import { render, staticRenderFns } from "./nav-item.vue?vue&type=template&id=76305eaa&scoped=true&"
import script from "./nav-item.vue?vue&type=script&lang=js&"
export * from "./nav-item.vue?vue&type=script&lang=js&"
import style0 from "./nav-item.vue?vue&type=style&index=0&id=76305eaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76305eaa",
  null
  
)

export default component.exports