<template>
  <component :is="tag" class="card-container" :class="containerClass">
    <div class="card-header" :class="headerClass" :style="headerStyle" v-if="!noHeader">
      <slot name="header" :headerTitle="headerTitle">
        <div class="card-header-body">
          <div class="card-header-content">
            <h2 v-if="!hasHeaderSlot" class="card-header-title font f-s-20 l-h-28 f-w-800 m-b-0">{{ headerTitle }}</h2>
            <slot name="header"></slot>
          </div>
        </div>
      </slot>
    </div>
    <div class="card-body" :class="bodyClass" :style="bodyStyle">
      <slot name="body"></slot>
    </div>
    <div v-if="!noFooter" class="card-footer" :class="footerClass">
      <slot name="footer"></slot>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    headerTitle: {
      type: String,
      default: '',
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      type: [String, Object],
      default: 'p-x-32 p-t-16 p-b-32',
    },
    headerClass: {
      type: [String, Object],
      default: 'p-x-32 p-t-16 p-b-12',
    },
    headerStyle: {
      type: [String, Object],
      default: 'min-height: 92px',
    },
    bodyStyle: {
      type: [String, Object],
      default: '',
    },
    footerClass: {
      type: [String, Object],
      default: 'p-y-24 p-x-32',
    },
    containerClass: {
      type: [String, Object],
      default: 'm-b-16 border-radius-16',
    },
  },
  computed: {
    hasHeaderSlot() {
      return this.$slots.header;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  &-container {
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    box-sizing: border-box;
    // overflow: hidden;
    &.border-radius-8 {
      border-radius: 8px;
    }

    &.border-radius-12 {
      border-radius: 12px;
    }

    &.border-radius-16 {
      border-radius: 16px;
    }

    &.selected {
      border-color: #1B63F5 !important;
    }

    &.border-light-grey {
      border: 1px solid #E3E3E3;
    }
  }


  &-md {
    .card-header {
      padding: 12px 0px 12px 0;
      min-height: 65px;

      &-body {
        padding: 0px 32px 0px 32px;
      }

      &-content {
        display: flex;
        justify-content: space-between;
      }

      &-title {
        margin: 6px 0px
      }
    }
  }

  &-header {
    background-color: inherit;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    &-body {
      padding: 24px 0 12px 0;
    }

  }

  &-footer {
    // height: 96px;
    border-top: 1px solid #CBCBCB;
    background-color: transparent;
  }
}
</style>
