<template>
    <b-nav-item-dropdown ref="item-dropdown" no-caret :id="menu.name" :text="menu.name" class="peekage-nav-item w-100" :class="{
        'only-device': menu.onlyDevice,
        'active': isActive
    }"
    >
        <template #button-content>
            <div @click="handlePosition" class="w-100">
                <div @click="toggleVisibility" class="d-flex align-items-center justify-content-between p-x-20 w-100 after">
                    <span class="font nav-text text-color primary m-r-4">{{ menu.name }}</span>
                    <span class="icon chevron-down only-desktop" />
                    <span class="icon chevron-right only-device" />
                </div>
            </div>
        </template>

        <b-dropdown-text id="nav-item-style-1" class="p-0" @click.stop>
            <nav-item
                :menu-version="menu.version"
                :sub-menus="menu.sub" :menu-name="menu.name"
                @closeItem="handleCloseItem"
                @handleCollapse="$emit('handleCollapse')"
            />
        </b-dropdown-text>
    </b-nav-item-dropdown>
</template>

<script>
import NavItem from '~/components/layout/header/nav-item';

export default {
    components: {
        NavItem,
    },
    props: {
        menu: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isActive: false,
        };
    },
    methods: {
        handleCloseItem() {
            this.$refs['item-dropdown'].hide();
            this.toggleVisibility();
        },
        handlePosition() {
            if (window.innerWidth < 992) {
                return;
            }
            const targetEl = document.querySelectorAll('.peekage-nav-item');
            targetEl.forEach((element) => {
                if (element && element.id === 'Resources') {
                    element.style.position = 'relative';
                } else {
                    element.style.position = 'static';
                }
            });
        },
        toggleVisibility() {
            if (window.innerWidth > 992) {
                return;
            }
            window.document.querySelectorAll('.peekage-nav-item').forEach((item) => {
                // if (item.id !== this.menu.name) {
                item.classList.toggle('d-none');
                // }
            });
        },
    },
};
</script>

<style lang="scss">
.b-dropdown-text{
    padding: 0;
    margin: 0;
}
    .peekage-nav-item {
        outline: none;
        min-height: 57px;
        min-width: 124px;
        border-bottom: 2px solid transparent;
        padding: 16px 0 16px 0;
        position: relative;
        .after{
                ::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-51%);
                    width: 90%;
                    height: 2px;
                    background-color: #F4F4F4;
                }
                :last-child{
                    ::after{
                        content: none;
                    }
                }

            }

        &.show {
            padding: 0;
            .nav-link {
                display: none;
            }
        }

        .nav-text {
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            letter-spacing: -0.3px;
        }

        &.show, &.active {
            color: #FF4F66;
            .nav-link {
                color: #FF4F66 !important;
            }
            &::after {
                background-color: #FF4F66 !important;
            }
        }

        .dropdown {
            &-item {
                border-radius: 8px;
                padding: 0;
                width: 100%;
                white-space: normal !important;
            }
        }

        @media only screen and (min-width: 992px) {
            /* For desktop: */
            // position: static !important;
            padding: 8px 0 8px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 72px;
            border-bottom: transparent;
            border-bottom: 2px solid transparent;
            ::after{
                content: none;
            }
            &.show {
            .nav-link {
                display: block;
                outline: none;
            }
        }
            &:hover, &.show, &.active {
                border-bottom: 2px solid #FF4F66;
                .nav-link{
                    background-color: #FFF1F3;
                }
            }
            .nav-text {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                letter-spacing: -0.3px;
            }

            .dropdown {
                &-menu {
                    margin: 0;
                    &.show {
                        display: flex;
                        border-radius: var(--Sizing-Space-500, 20px);
                        background: linear-gradient(180deg, #FFF1F3 30%, #FFF 100%), var(--Color-White, #FFF);
                        background: #FFF;
                        box-shadow: 0px 20px 32px 0px rgba(0, 0, 0, 0.12);
                        margin-top: 2px;

                    }
                }
            }
        }
    }
</style>
