<template>
	<b-modal
		:id="id"
		v-model="show"
		:hide-footer="hideFooter"
		:hide-header="hideHeader"
		:header-class="headerClass"
		:body-class="bodyClass"
		:footer-class="footerClass"
		centered
		modal-class="fullscreen-modal"
		dialog-class="fullscreen-modal-dialog"
		no-close-on-backdrop
		no-close-on-esc
		@hidden="hidden"
	>
		<template #modal-header>
			<slot name="head">
				<div
					style="max-width: 1024px"
					class="w-100 m-auto"
				>
					<div class="d-flex align-items-center p-l-10">
						<button
							class="m-0 p-0 btn fab fab-lg icon close color primary"
							type="button"
							@click="hide"
						/>
						<div class="p-y-14 m-l-12">
							<span class="font f-s-18 l-h-28 f-w-700 text-color primary">{{ header }}</span>
						</div>
					</div>
				</div>
			</slot>
		</template>

		<div :class="bodyClass">
			<slot />
		</div>

		<template #modal-footer>
			<slot name="foot">
				<tuber
					class="btn lg tertiary"
					text="Cancel"
					@click="hide"
				/>
				<tuber
					:loading="loading"
					:disabled="disabled"
					class="btn lg m-l-16"
					:class="`${okVariant}`"
					:text="okText"
					@click="submit"
				/>
			</slot>
		</template>
	</b-modal>
</template>

<script>
export default {
	props: {
		beforeClose: {
			type: [Function, Boolean],
			default: false,
		},
		value: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: Math.random(),
			required: true,
		},
		header: {
			type: String,
			default: 'Modal Header',
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		okText: {
			type: String,
			default: 'Update Marks',
		},
		hidden: {
			type: Function,
			default: () => {},
		},
		ok: {
			type: Function,
			default() {
				this.$emit('submit').hide();
			},
		},
		okVariant: {
			type: String,
			default: 'primary',
		},
		cancel: {
			type: Function,
			default() {},
		},
		hideFooter: {
			type: Boolean,
			default: false,
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		noCloseOnEsc: {
			type: Boolean,
			default: false,
		},
		bodyClass: {
			type: String,
			default: 'm-y-16',
		},
		headerClass: {
			type: String,
			default: 'm-y-16',
		},
		bodyClass: {
			type: String,
			default: 'fullscreen-modal-body',
		},
		footerClass: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			// loading: false
		};
	},
	computed: {
		show: {
			get() {
				return this.value;
			},
			set(show) {
				this.$emit('input', show);
			},
		},
	},
	methods: {
		async hide() {
			try {
				if (typeof this.beforeClose === 'function') {
					await new Promise((resolve, reject) => {
						this.beforeClose(resolve, reject);
					});
				}
				this.$bvModal.hide(this.id);
				this.cancel();
			} catch (error) {
				// console.log(error);
			}
		},
		submit() {
			this.ok(this);
		},
	},
};
</script>

<style lang="scss">
.fullscreen-modal {
    padding: 0;
    .fullscreen-modal-dialog {
        margin: 0;
        max-width: 100%;
        height: 100vh;
        .modal-content {
            position: fixed;
            overflow: auto;
            border-radius: 0;
            height: 100%;
        }
        .modal-header {
            padding: 16px 0 17px 0;
            border-bottom: none;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
        }

        .modal-footer {
            padding: 24px 32px;
        }

        .modal-body {
            padding: 0 32px;
        }
		.story-modal-header{
		&.modal-header{
			padding: 0 24px 0 24px !important;
			
			.close{
				padding: 0;
				margin: 0;
			}
		}
	}
	.story-modal-body{
		height: 100%;
		&.modal-body{
			padding: 0 !important;
		}
	}
	.story-modal-footer{
		&.modal-footer{
			padding: 0 !important;
			margin: 0 !important;
			flex-wrap: wrap;
			.btn{
				min-width: 100%;
			}
		}
	}
    }
	@media screen and (min-width: 992px) {
			.fullscreen-modal-dialog{
				.story-modal-header{
					&.modal-header{
						padding: 0 24px 12px 24px !important;
						.close{
							margin-top: 10px;
						}
					}
				}
				.story-modal-footer{
					&.modal-footer{
						.btn{
							min-width: 224px;
						}
					}
				}
			}
		}
}
</style>
