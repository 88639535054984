import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _35ce83d3 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _6232a3e7 = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _1580d708 = () => interopDefault(import('../pages/beauty-product-sampling.vue' /* webpackChunkName: "pages/beauty-product-sampling" */))
const _3bad7034 = () => interopDefault(import('../pages/case-studies.vue' /* webpackChunkName: "pages/case-studies" */))
const _b81793a2 = () => interopDefault(import('../pages/consumer-apps.vue' /* webpackChunkName: "pages/consumer-apps" */))
const _0838669e = () => interopDefault(import('../pages/consumer-apps-campaign.vue' /* webpackChunkName: "pages/consumer-apps-campaign" */))
const _40d0f448 = () => interopDefault(import('../pages/consumer-insights-company.vue' /* webpackChunkName: "pages/consumer-insights-company" */))
const _028ca134 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _16232c7b = () => interopDefault(import('../pages/d2c-brands-solutions.vue' /* webpackChunkName: "pages/d2c-brands-solutions" */))
const _3987bbaa = () => interopDefault(import('../pages/d2c-brands-solutions copy.vue' /* webpackChunkName: "pages/d2c-brands-solutions copy" */))
const _66617f50 = () => interopDefault(import('../pages/enterprise-solutions.vue' /* webpackChunkName: "pages/enterprise-solutions" */))
const _5127325c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _10085967 = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _9fbbd8d4 = () => interopDefault(import('../pages/natural-shoppers-community.vue' /* webpackChunkName: "pages/natural-shoppers-community" */))
const _55e5bace = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _08cbb2dc = () => interopDefault(import('../pages/product-sampling-canada.vue' /* webpackChunkName: "pages/product-sampling-canada" */))
const _08cbd8b0 = () => interopDefault(import('../pages/product-sampling-north-america.vue' /* webpackChunkName: "pages/product-sampling-north-america" */))
const _76bbe534 = () => interopDefault(import('../pages/product-sampling-program.vue' /* webpackChunkName: "pages/product-sampling-program" */))
const _dd927d64 = () => interopDefault(import('../pages/product-sampling-united-states.vue' /* webpackChunkName: "pages/product-sampling-united-states" */))
const _59819276 = () => interopDefault(import('../pages/product-sampling copy.vue' /* webpackChunkName: "pages/product-sampling copy" */))
const _458923be = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _00f7c666 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _3234c0b0 = () => interopDefault(import('../pages/typeform.vue' /* webpackChunkName: "pages/typeform" */))
const _6e8b0bf2 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _285412c4 = () => interopDefault(import('../pages/coupon/redeem-status.vue' /* webpackChunkName: "pages/coupon/redeem-status" */))
const _7b259fd4 = () => interopDefault(import('../pages/redirections/redeem-token.vue' /* webpackChunkName: "pages/redirections/redeem-token" */))
const _d42d87d8 = () => interopDefault(import('../pages/widget/demo.vue' /* webpackChunkName: "pages/widget/demo" */))
const _43913e38 = () => interopDefault(import('../pages/campaigns/hartz/ca.vue' /* webpackChunkName: "pages/campaigns/hartz/ca" */))
const _20c737c0 = () => interopDefault(import('../pages/campaigns/hartz/mg.vue' /* webpackChunkName: "pages/campaigns/hartz/mg" */))
const _042779b8 = () => interopDefault(import('../pages/campaigns/hartz/us.vue' /* webpackChunkName: "pages/campaigns/hartz/us" */))
const _341d04e6 = () => interopDefault(import('../pages/download-files/pdf/coupon.vue' /* webpackChunkName: "pages/download-files/pdf/coupon" */))
const _1b1ce3e8 = () => interopDefault(import('../pages/download-files/pdf/email.vue' /* webpackChunkName: "pages/download-files/pdf/email" */))
const _995f3a8e = () => interopDefault(import('../pages/postwidget/_id.vue' /* webpackChunkName: "pages/postwidget/_id" */))
const _c285a8c0 = () => interopDefault(import('../pages/use-case/_case.vue' /* webpackChunkName: "pages/use-case/_case" */))
const _5edbea48 = () => interopDefault(import('../pages/widget/_id/index.vue' /* webpackChunkName: "pages/widget/_id/index" */))
const _7a8fc911 = () => interopDefault(import('../pages/widget/_id/terms.vue' /* webpackChunkName: "pages/widget/_id/terms" */))
const _55865e98 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _35ce83d3,
    name: "about"
  }, {
    path: "/app",
    component: _6232a3e7,
    name: "app"
  }, {
    path: "/beauty-product-sampling",
    component: _1580d708,
    name: "beauty-product-sampling"
  }, {
    path: "/case-studies",
    component: _3bad7034,
    name: "case-studies"
  }, {
    path: "/consumer-apps",
    component: _b81793a2,
    name: "consumer-apps"
  }, {
    path: "/consumer-apps-campaign",
    component: _0838669e,
    name: "consumer-apps-campaign"
  }, {
    path: "/consumer-insights-company",
    component: _40d0f448,
    name: "consumer-insights-company"
  }, {
    path: "/contact",
    component: _028ca134,
    name: "contact"
  }, {
    path: "/d2c-brands-solutions",
    component: _16232c7b,
    name: "d2c-brands-solutions"
  }, {
    path: "/d2c-brands-solutions%20copy",
    component: _3987bbaa,
    name: "d2c-brands-solutions copy"
  }, {
    path: "/enterprise-solutions",
    component: _66617f50,
    name: "enterprise-solutions"
  }, {
    path: "/faq",
    component: _5127325c,
    name: "faq"
  }, {
    path: "/how-it-works",
    component: _10085967,
    name: "how-it-works"
  }, {
    path: "/natural-shoppers-community",
    component: _9fbbd8d4,
    name: "natural-shoppers-community"
  }, {
    path: "/privacy",
    component: _55e5bace,
    name: "privacy"
  }, {
    path: "/product-sampling-canada",
    component: _08cbb2dc,
    name: "product-sampling-canada"
  }, {
    path: "/product-sampling-north-america",
    component: _08cbd8b0,
    name: "product-sampling-north-america"
  }, {
    path: "/product-sampling-program",
    component: _76bbe534,
    name: "product-sampling-program"
  }, {
    path: "/product-sampling-united-states",
    component: _dd927d64,
    name: "product-sampling-united-states"
  }, {
    path: "/product-sampling%20copy",
    component: _59819276,
    name: "product-sampling copy"
  }, {
    path: "/sitemap",
    component: _458923be,
    name: "sitemap"
  }, {
    path: "/terms",
    component: _00f7c666,
    name: "terms"
  }, {
    path: "/typeform",
    component: _3234c0b0,
    name: "typeform"
  }, {
    path: "/verify-email",
    component: _6e8b0bf2,
    name: "verify-email"
  }, {
    path: "/coupon/redeem-status",
    component: _285412c4,
    name: "coupon-redeem-status"
  }, {
    path: "/redirections/redeem-token",
    component: _7b259fd4,
    name: "redirections-redeem-token"
  }, {
    path: "/widget/demo",
    component: _d42d87d8,
    name: "widget-demo"
  }, {
    path: "/campaigns/hartz/ca",
    component: _43913e38,
    name: "campaigns-hartz-ca"
  }, {
    path: "/campaigns/hartz/mg",
    component: _20c737c0,
    name: "campaigns-hartz-mg"
  }, {
    path: "/campaigns/hartz/us",
    component: _042779b8,
    name: "campaigns-hartz-us"
  }, {
    path: "/download-files/pdf/coupon",
    component: _341d04e6,
    name: "download-files-pdf-coupon"
  }, {
    path: "/download-files/pdf/email",
    component: _1b1ce3e8,
    name: "download-files-pdf-email"
  }, {
    path: "/postwidget/:id?",
    component: _995f3a8e,
    name: "postwidget-id"
  }, {
    path: "/use-case/:case?",
    component: _c285a8c0,
    name: "use-case-case"
  }, {
    path: "/widget/:id",
    component: _5edbea48,
    name: "widget-id"
  }, {
    path: "/widget/:id?/terms",
    component: _7a8fc911,
    name: "widget-id-terms"
  }, {
    path: "/",
    component: _55865e98,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
