<template>
<input-container :tooltip-text="tooltipText" class="input-datepicker" :label="label" :tooltip-custom-style="tooltipCustomStyle" :assistive="assistive" :no-assistive="noAssistive" :class="{ focused, disabled, invalid }">
    <template #container>
        <!-- <span class="icon search size sm m-r-4 m-l-12" v-if="search"></span> -->
        <DatePicker 
			type="date" 
			popup-class="custom-datepicker font f-s-16 l-h-20 f-w-500" 
			@focus="focus(true)" 
			@blur="blur(false)" 
			@input="handler" 
			v-model="date" 
			:confirm="false" 
			:disabled="disabled" 
			:placeholder="placeholder" 
			:format="format" 
			:default-value="date" 
			:disabled-date="getDisabledDates" 
			:editable="editable" 
			:clearable="clearable" 
            range-separator="~"
			:lang="{
              formatLocale: {},
              monthBeforeYear: false,
            }">
            <template #icon-calendar>
                <span class="icon date color primary size x-sm"></span>
            </template>
        </DatePicker>
        <!-- firstDayOfWeek: 0, -->
        <!-- type="date" -->
        <button type="button" class="btn fab fab-sm icon close color primary m-r-16" v-if="showCleanButton" @click="clear"></button>
        <span class="font m-r-12 l-h-24 f-s-16 f-w-500" v-if="enhancerLabel">{{ enhancerLabel }}</span>
    </template>
</input-container>
</template>

<script>
import moment from 'moment';
import get from 'lodash/get';
import DatePicker from 'vue2-datepicker';
import InputContainer from './input-container.vue';
// import 'vue2-datepicker/index.css';

export default {
    components: {
        InputContainer,
        DatePicker,
    },
    data() {
        return {
            date: get(this, 'defaultValue', ''),
            focused: false,
        };
    },
    props: {
        clearable: {
            type: Boolean,
            default: false,
        },
        tooltipCustomStyle: {
            type: Object,
            default: () => ({})
        },
        tooltipText: {
            type: String,
            default: ''
        },
        format: {
            type: String,
            default: 'MM/DD/YYYY',
        },
        noAssistive: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        enhancerLabel: {
            type: [Boolean, String],
            default: false,
        },
        search: {
            type: Boolean,
            default: false,
        },
        clean: {
            type: Boolean,
            default: false,
        },
        value: {},
        label: {
            type: [String, Boolean],
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        assistive: {
            type: [String, Boolean],
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        classes: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        defaultValue: {
            type: [String, Date, Object],
            default: '',
        },
    },
    computed: {
        showCleanButton() {
            return !this.disabled && this.clean && this.value.length;
        },
        invalid() {
            return this.error;
        },
    },
    watch: {
        value(date) {
            this.date = new Date(date);
        },
        defaultValue(date) {
            if (date) {
                this.date = new Date(date);
            }
        },
    },
    methods: {
        handler() {
            this.$emit('input', moment(this.date).format(this.format));
        },
        focus(focus) {
            this.focused = focus;
        },
        blur(blur) {
            this.focused = blur;
        },
        clear() {
            this.$refs.input.clear();
        },
        getDisabledDates(d) {
            return d < new Date();
        },
    },
};
</script>

<style lang="scss">
.custom-datepicker {
    z-index: 1050;
    width: 386px;
    height: 434px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0px 16px 24px 0px #0000001F;
    font-family: "manrope", "Roboto", sans-serif;

    .mx-datepicker {
        &-content {
            width: 100%;
            height: 100%;
        }

        &-body {
            width: inherit;
            height: inherit;
        }
    }

    .mx-calendar {
        &-panel-date {
            width: inherit;
            height: inherit;
        }

        &-header {
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .mx-btn {
                height: inherit;
                width: 48px;
                background-color: transparent;
                border: unset;
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
                font-family: "manrope", "Roboto", sans-serif;
            }

            &-label {
                height: inherit;
                display: flex;
                align-items: center;
                // flex-direction: row-reverse;
            }

            .mx-btn-icon-double {

                &-right,
                &-left {
                    display: none;
                }
            }

            .mx-btn-icon {

                &-right,
                &-left {
                    position: absolute;
                }

                &-right {
                    right: 0px;
                }

                &-left {
                    left: 0px;
                }
            }

            .mx-calendar-decade-separator {
                width: 40px;
                position: relative;
                &::after {
                    content: "~";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &-content {

            .mx-table {
                width: 100%;

                &-year {}

                &-month,
                &-year {
                    tr {
                        td {
                            &.cell {
                                height: 50px;
                                text-align: center;
                                cursor: pointer;

                                &:hover {
                                    background-color: #EEEEEE;
                                }

                                &:focus,
                                &:active {
                                    background: #EEEEEE;
                                    box-shadow: inset 0px 0px 100px rgba(0, 0, 0, 0.08);
                                }

                                &.active {
                                    color: #ffffff;
                                    background-color: #141414;
                                }
                            }
                        }
                    }
                }

                &-date {
                    thead {
                        tr {
                            th {
                                height: 50px;
                                width: 50px;
                                text-align: center;
                                font-size: 16px;
                                line-height: 20px;
                                font-weight: 500;
                                font-family: "manrope", "Roboto", sans-serif;
                                color: #757575;

                                &.mx-week-number-header {
                                    display: none;
                                    width: 0px;
                                }

                                // display: flex;
                                // align-items: center;
                                // justify-content: center;
                            }
                        }
                    }

                    .mx-date-row {
                        height: 50px;
                        width: 50px;

                        td {
                            text-align: center;
                            height: inherit;
                            width: inherit;

                            &.mx-week-number {
                                display: none;
                                width: 0px;
                            }

                            // height: 50px;
                            // display: flex;
                            // align-items: center;
                            // justify-content: center;

                            &.cell {
                                div {
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-weight: 700;
                                    font-family: "manrope", "Roboto", sans-serif;
                                    height: inherit;
                                    width: inherit;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 50%;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: #EEEEEE;
                                    }

                                    &:focus,
                                    &:active {
                                        background: #EEEEEE;
                                        box-shadow: inset 0px 0px 100px rgba(0, 0, 0, 0.08);
                                    }
                                }

                                &.disabled {
                                    color: #AFAFAF;
                                }

                                &.active {
                                    color: #ffffff;

                                    div {
                                        background-color: #141414;
                                    }
                                }

                                &.not-current-month {
                                    color: #AFAFAF;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.input-datepicker {
    .mx-datepicker {
        width: 100%;
        height: 48px;
        min-height: 48px;

        &-body {}

        .mx-input-wrapper {
            height: inherit !important;
            min-height: inherit !important;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 12px;

            .mx-icon-calendar {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 4px;
            }

            .mx-input {
                padding: 14px 12px;
                width: 100%;
                height: inherit !important;
                background-color: transparent;
                border: unset;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                font-family: "manrope", "Roboto", sans-serif;

                &:focus {
                    box-shadow: unset;
                    outline: unset;
                }

                &:disabled {
                    color: #AFAFAF;
                }
            }
        }
    }
}
</style>
