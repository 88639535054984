<template>
    <div class="style-1-container">
        <div class="d-flex flex-column p-x-20 p-y-20 p-b-small-0 cursor-default platform-item">
            <div @click="$emit('handleCollapse')" v-for="(item, i) in subMenus.filter(item => item.type === 'NAV_ITEM')" :key="i">
                <nuxt-link hreflang="en-ca" :to="item.path" class="d-flex p-l-28 p-l-small-16 p-r-20 p-r-small-16 p-y-16 style-1-nav-item">
                    <div class="d-flex">
                        <img :src="$image(item.imgPath)" :alt="item.title" class="nav-img m-r-small-16">
                        <div class="flex-column mobile-version" style="gap: 8px;">
                            <span class="font f-s-20 l-h-28 f-w-800 l-t-s-75 f-s-small-18 l-g-small-24 f-w-small-700">{{ item.title }}</span>
                            <div class="icon-container">
                                <span class="icon size x-sm arrow-right icon-arrow" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center" style="gap: 12px;">
                        <div class="align-items-center desktop-version" style="gap: 8px;">
                            <span class="font f-s-20 l-h-28 f-w-800 l-t-s-75">{{ item.title }}</span>
                            <div class="icon-container">
                                <span class="icon size x-sm arrow-right icon-arrow" />
                            </div>
                        </div>
                        <span class="font f-s-14 l-h-20 f-w-400">{{ item.subTitle }}</span>
                    </div>
                </nuxt-link>
            </div>
        </div>
        <Divider class="divider-vertical header-divider only-device p-x-20" />
        <div class="why justify-content-start p-x-small-20 p-b-small-20" @click="$emit('handleCollapse')">
            <span class="font f-s-16 l-h-20 l-t-s-3 f-w-700 m-b-8">
                Why Peekage?
            </span>
            <span class="font f-s-12 l-h-20 f-w-400 m-b-32 m-b-small-20">Access a diverse community of 5M+ shoppers and drive retail velocity with geo-targeting. Get live consumer data in days, not weeks, with affordable, all-in-one product testing solutions that are easy to use, requiring no data science expertise.</span>
            <div class="d-flex pointer w-fit" @click="handleScroll">
                <span class="font f-s-14 l-h-16 f-w-700 l-t-s-3 text-color" style="color: #FF3357;">Learn more</span>
                <span class="icon size tiny arrow-right active-only" style="color: #FF3357;" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavStyle1',
    props: {
        subMenus: {
            type: Array,
            default: () => ([]),
        },
    },
    methods: {

        async handleScroll() {
            const path = this.$route.path;
            if (path !== '/') {
                this.$router.push('/#WhyPeekage');
            }
            const el = document.getElementById('WhyPeekage');
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        },
    },

};
</script>

<style lang="scss" scope>

.w-fit{
    width: fit-content;
}
.cursor-default{
    cursor: default;
}
.style-1-container{
        background: linear-gradient(180deg, #FFF1F3 30%, #FFf 100%);
        border-radius: 20px;
        .platform-item{
            border-radius: 20px;
            background-color: #fff;
            gap: 20px;
        }
    .active-only{
        background-color: #FF3357 !important;
    }
    color: #000;
    display: flex;
    flex-direction: row;
    @media (max-width: 992px) {
        background: unset;
        display: flex;
        flex-direction: column;
    }
}
    .mobile-version{
        display: none;
        @media (max-width: 992px) {
            display: flex;
        }
    }
    .desktop-version{
    display: none;
    @media (min-width: 992px) {
        display: flex;
    }
}
    .why{
        cursor: default;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        .pointer{
            cursor: pointer;
        }
        @media screen and (min-width: 992px) {
            width: 60%;
            justify-content: center;
            padding: 80px 40px;
        }
    }
    .header-divider {
        width: 90%;
        height: 2px;
        margin: 20px auto;
        background-color: #F4F4F4;
        @media screen and (min-width: 992px) {
            height: unset;
            width: 2px;
            margin: 0 24px;
        }
    }
    .style-1-nav-item {
        .nav-img {
        width: 80px;
        height: 80px;
        @media screen and (min-width: 992px) {
            width: 120px;
            height: 120px;
        }
    }
        text-decoration: none;
        color: #000;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        border: 1px solid var(--Color-Nuetral-50, #F4F4F4);
        background: var(--Color-White, #FFF);
        gap: 12px;
        &:hover{
            text-decoration: none;
            color: inherit;
        }
        &:focus{
            *{
                color: #000 !important;
            }
        }

        @media screen and (min-width: 992px) {
            gap: 32px;
            flex-direction: row;
            // :hover{
            //     border: 1px solid var(--Color-Nuetral-50, #ED1138);
            // }
        }

        .icon-container{
            padding: 4px;
            background-color: #F4F4F4;
            height: 28px;
            width: max-content;
            border-radius: var(--Sizing-Space-100, 4px);
            .icon-arrow {
                background-color: #515151;
            }
        }

        &:hover {
            border: 1px solid var(--Color-Primary-400, #FF647F);
            .icon-container{
                background-color: #FFDFE5;
                .icon-arrow {
                    background-color: #ED1138;
                }
            }
        }
    }

</style>
