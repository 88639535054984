<template>
    <b-nav-item :href="menu.uri" class="peekage-nav-item justify-content-center" :class="{
                    'only-device': menu.onlyDevice,
                    // 'active': isActive
                }"
                ref="activeNavItem"
    >
        <div class="p-x-20 d-flex align-items-center extra-small-device w-100">
            <span class="font f-s-16 l-h-20 f-w-500 l-t-s-3 nav-text text-color tertiary">
                {{ menu.name }}
            </span>
            <tuber to="/contact?talk-to-an-expert=true" class="btn primary p-x-12 f-w-700 l-t-s-3 l-h-20 p-y-10 flex-fill" text-class="font f-s-16 l-h-20 f-w-700 l-t-s-3">
                Talk to an expert
            </tuber>
        </div>
    </b-nav-item>
</template>

<script>
export default {
    props: {
        menu: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss" scoped>
.extra-small-device{
    gap: 48px;
    @media (max-width: 405px) {
        flex-direction: column;
        align-items: start;
        gap: 8px;

        span{
            align-self: start;
        }
    }
}
.nav-link{
    justify-content: center;
    @media (max-width: 405px) {
        justify-content: start;
        .extra-small-device{
            width: 100%;
            button{
                width: 100%;
            }
        }
    }
}
</style>
